import dynamic from 'next/dynamic'
import parse, { attributesToProps, domToReact } from 'html-react-parser'
import Link from "next/link"
const FeaturedJobs = dynamic(() => import('../components/landing-page/FeaturedJobs'))
const HomepageMap = dynamic(() => import('../components/landing-page/HomepageMap'))
const LandingWorkers = dynamic(() => import('../components/landing-page/LandingWorkers'))
const LandingSearchBar = dynamic(() => import('../components/landing-page/LandingSearchBar'))
const LandingHeroAndLogos = dynamic(() => import('../components/landing-page/LandingHeroAndLogos'))

const slots = [
  {
    id: 'jobs',
    el: {
      tag: 'class',
      value: 'wp-block-ajax-job-list',
    },
    content: (data) => (
      <FeaturedJobs {...data} />
    ),
  },
  {
    id: 'map',
    el: {
      tag: 'class',
      value: 'wp-block-ajax-job-map',
    },
    content: (data) => (
      <HomepageMap {...data} />
    )
  },
  {
    id: 'landing-searchbar',
    el: {
      tag: 'class',
      value: 'wp-block-ajax-job-search',
    },
    content: (data) => (
      <LandingSearchBar {...data} />
    )
  },
  {
    id: 'landing-workers',
    el: {
      tag: 'class',
      value: 'wp-block-ajax-landing-workers',
    },
    content: (data) => (
      <LandingWorkers {...data} />
    )
  },
  {
    id: 'landing-hero-and-logos',
    el: {
      tag: 'class',
      value: 'wp-block-ajax-hero-and-logos',
    },
    content: (data) => (
      <LandingHeroAndLogos {...data} />
    )
  },
]

export default class WPParser {
  constructor(input) {
    this.body = this.getTagContent(input, '<body.*?>', '<\\/body>')
    this.head = this.getTagContent(input, '<!-- start:wp-embed -->', '<!-- end:wp-embed -->')
    this.title = this.getTagContent(input, '<title.*?>', '<\\/title>')
    this.bodyClass = this.getTagContent(input, '<body', '>').match(/class\=\"([\s\S]*?)\"/)?.[1] || ''
  }

  getTagContent(input, openTag, closeTag) {
    return new RegExp(`${openTag}([\\s\\S]*?)${closeTag}`).exec(input)?.[1] || '';
  }

  static parse(input, props = {}) {
    let mapId = 1
    const parseOptions = {
      replace: domNode => {
        if (domNode.attribs?.href && domNode.name === 'a') {
          const { origin, pathname } = new URL(domNode.attribs.href)
          if (origin === process.env.NEXT_PUBLIC_LADDER_WP) {
            delete domNode.attribs.href
            const tagAttrs = attributesToProps(domNode.attribs)
            return (<Link href={pathname}>
              <a {...tagAttrs}>
                {domToReact(domNode.children)}
              </a>
            </Link>)
          }
        }

        const slot = slots.find(s => domNode.attribs && domNode.attribs[s.el.tag]?.includes(s.el.value))
        if (slot) {
          const TagName = domNode.name
          const tagAttrs = attributesToProps(domNode.attribs)
          const slotProps = props[slot.id] || {}
          if (slot.id === 'map') {
            slotProps.containerId = 'map_' + mapId
            mapId++
          }
          return (
            <TagName {...tagAttrs}>
              {slot.content(slotProps)}
            </TagName>
          )
        }
      }
    }

    return parse(input, parseOptions)
  }
}