import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { frontendURL, publicURL } from "../lib/ladder"

export default function SEO({title, description, path, override, publicVersion, image, noindex}) {
  const router = useRouter();
  const sTitle = title ? title + " | Ladder" : "Ladder"
  const sUrl = (publicVersion ? publicURL() : frontendURL()) + (path || router.pathname);

  return(
    <NextSeo 
     title={override ? title : sTitle} 
     description={description || 'Construction recruiting on autopilot'} 
     canonical={sUrl}
     noindex={noindex || false}
     openGraph={{
       url: sUrl,
       title: override ? title : sTitle,
       description: description || 'Construction recruiting on autopilot.',
       images: [
         {
           url: (image ? image : `${frontendURL()}/ogimage.png`),
           alt: 'Ladder Open Graph Image',
         }
       ],
       type: 'website',
       site_name: 'Ladder',
     }}
     twitter={{
       handle: '@ladderforwork',
       site: '@ladderforwork',
       cardType: 'summary_large_image',
     }}
     ></NextSeo>
  )
}